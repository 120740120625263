import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"

class ContactTemplate extends Component {

    constructor(props) {
        super(props)
        this.currentPage = this.props.data.wordpressPage
    }

    renderEmployees() {
        return this.currentPage.acf.employees
            .map(employe =>
                <div key={employe.name} className="w-full md:w-1/2 lg:w-1/4 md:px-4 pb-50 md:pb-70 lg:pb-100 wide:pb-150">
                    <video className="w-full" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true">
                        <source src={employe.movie.localFile.publicURL} type="video/mp4" />
                    </video>
                    <article className="text-white mt-6">
                        <h3 className="title-sm" dangerouslySetInnerHTML={{ __html: employe.name }}></h3>
                        <h4 className="title-sm font-thin mt-2" dangerouslySetInnerHTML={{ __html: employe.title }}></h4>
                        <p className="text-standard mt-4" dangerouslySetInnerHTML={{ __html: employe.contact }}></p>
                    </article>
                </div>
            )
    }

    render() {
        return (
            <Layout footerfixed={true}>
                <main className="container px-5 xl:px-0 z-40">
                    <h1 className="title-lg text-center pt-200" dangerouslySetInnerHTML={{ __html: this.currentPage.acf.title }} />
                    <div className="w-full lg:w-2/3 mx-auto mt-100 md:mt-200 lg:mt-500 wide:mt-500 mb-100 lg:mb-150 wide:mb-250">
                        <article className="text-large text-white mt-6 md:mt-8 wide:mt-50" dangerouslySetInnerHTML={{ __html: this.currentPage.content }} />
                    </div>
                    <section className="employees">
                        <div className="overflow-hidden">
                            <div className="flex flex-wrap md:-mx-4">
                                {this.renderEmployees()}
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        )
    }
}

export default ContactTemplate

export const pageQuery = graphql`
query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        title
        employees {
            name
            title
            contact
            movie {
                localFile {
                    publicURL
                }
            }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
}
`